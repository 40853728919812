import Amplify, { AuthModeStrategyType, DataStore, syncExpression } from 'aws-amplify';
import { startOfDay, subMinutes } from 'date-fns';
import config from 'src/aws-exports';
import {
    CleaningTaskRecord,
    Cooling,
    Delivery,
    Freezing,
    FryerCheck,
    Reheating,
    SurfaceAnalysis,
    TemperatureReading,
    TemperatureRecord,
    TraceabilityLabel
} from 'src/models';

const now = new Date();
const startOfDayTimestamp = subMinutes(startOfDay(now), 1).getTime().toString();

type SyncTimestampKey =
    | 'cleaningTaskRecord'
    | 'cooling'
    | 'freezing'
    | 'delivery'
    | 'fryerCheck'
    | 'temperatureRecord'
    | 'traceabilityLabel'
    | 'surfaceAnalysis'
    | 'reheating'
    | 'temperatureReading';

const syncTimestamps = {
    cleaningTaskRecord: startOfDayTimestamp,
    cooling: startOfDayTimestamp,
    freezing: startOfDayTimestamp,
    delivery: startOfDayTimestamp,
    fryerCheck: startOfDayTimestamp,
    temperatureRecord: startOfDayTimestamp,
    traceabilityLabel: startOfDayTimestamp,
    surfaceAnalysis: startOfDayTimestamp,
    reheating: startOfDayTimestamp,
    temperatureReading: startOfDayTimestamp
};

const restartDataStore = async () => {
    await DataStore.stop();
    await DataStore.start();
};

export const setSyncTimestamp = async (key: SyncTimestampKey, value: string) => {
    syncTimestamps[key] = value;
    configure();
    await restartDataStore();
};

export const configure = (): void => {
    Amplify.configure({
        ...config,
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        DataStore: {
            authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
        }
    });

    DataStore.configure({
        errorHandler: (error: any) => {
            throw new Error(JSON.stringify(error));
        },
        maxRecordsToSync: 10000,
        syncExpressions: [
            syncExpression(TemperatureRecord, () => {
                return (temperatureRecord) =>
                    temperatureRecord.timestamp('gt', syncTimestamps.temperatureRecord);
            }),
            syncExpression(TraceabilityLabel, () => {
                return (traceabilityLabel) =>
                    traceabilityLabel
                        .timestamp('gt', syncTimestamps.traceabilityLabel)
                        .part('eq', 4);
            }),
            syncExpression(Delivery, () => {
                return (delivery) => delivery.timestamp('gt', syncTimestamps.delivery);
            }),
            syncExpression(CleaningTaskRecord, () => {
                return (cleaningTaskRecord) =>
                    cleaningTaskRecord.timestamp('gt', syncTimestamps.cleaningTaskRecord);
            }),
            syncExpression(Cooling, () => {
                return (cooling) => cooling.timestamp('gt', syncTimestamps.cooling);
            }),
            syncExpression(Freezing, () => {
                return (freezing) => freezing.timestamp('gt', syncTimestamps.freezing);
            }),
            syncExpression(FryerCheck, () => {
                return (fryerCheck) => fryerCheck.timestamp('gt', syncTimestamps.fryerCheck);
            }),
            syncExpression(SurfaceAnalysis, () => {
                return (surfaceAnalysis) =>
                    surfaceAnalysis.timestamp('gt', syncTimestamps.surfaceAnalysis);
            }),
            syncExpression(Reheating, () => {
                return (reheating) => reheating.timestamp('gt', syncTimestamps.reheating);
            }),
            syncExpression(TemperatureReading, () => {
                return (temperatureReading) =>
                    temperatureReading.timestamp('gt', syncTimestamps.temperatureReading);
            })
        ],
        syncPageSize: 300
    });
    return;
};
